import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

  NProgress.start()

  const hasToken = getToken()
  const url = location.pathname;
  document.title = getPageTitle()
  if(!hasToken){
    if(url != '/login' && url != '/register' && url != '/reset'){
        window.location.href = '/login';
    }
  }
  NProgress.done()

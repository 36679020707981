import Cookies from 'js-cookie'

const TokenKey = 'hbwzndcfhuhaqlum'
const RoleKey = 'idjwd41104l4'
const NickNameKey = 'yx733n4dnfxv'
const AccountKey = 'g1jvgy5zi84k'
const UidKey = 'sdalfisnsnd'
const HeadimgKey = 'jvsksajfnna'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function setUid(uid) {
  return Cookies.set(UidKey, uid)
}

export function getRole() {
  return Cookies.get(RoleKey)
}

export function getUid() {
  return Cookies.get(UidKey)
}

export function setRole(role) {
  return Cookies.set(RoleKey, role)
}

export function getNickname() {
  return Cookies.get(NickNameKey)
}

export function setNickname(name) {
  return Cookies.set(NickNameKey, name)
}

export function getAccount() {
  return Cookies.get(AccountKey)
}

export function setAccount(name) {
  return Cookies.set(AccountKey, name)
}

export function getHeadimg() {
  return Cookies.get(HeadimgKey)
}

export function setHeadimg(name) {
  return Cookies.set(HeadimgKey, name)
}

export function removeToken() {
  Cookies.remove(TokenKey)
  Cookies.remove(RoleKey)
  Cookies.remove(AccountKey)
  Cookies.remove(UidKey)
  Cookies.remove(HeadimgKey)
  return Cookies.remove(NickNameKey)
}

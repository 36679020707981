const Login = () => import('@/views/login/login')
const Register = () => import('@/views/register')
const Reset = () => import('@/views/reset')
const Project = () => import('@/views/project/list')
const ProjectAdd = () => import('@/views/project/add')
// const ProjectEdit = () => import('@/views/project/edit')
// const ProjectUsers = () => import('@/views/project/users')
const ProjectRecycle = () => import('@/views/project/recycle')
const UsersSetting = () => import('@/views/users/index')
const UsersNotification = () => import('@/views/users/notification')


// const ProjectEdit = () => import('@/views/project/edit/input')
const ProjectEditData = () => import('@/views/project/edit/enter')
const ProjectEditList = () => import('@/views/project/edit/list')
const ProjectEditInput = () => import('@/views/project/edit/layout')
const ProjectEditMember = () => import('@/views/project/edit/member')
const ProjectEditArchive = () => import('@/views/project/edit/archive')
const ProjectEditWork = () => import('@/views/project/edit/work')

const IndexLayout = () => import('@/views/layout/index')
const Layout = () => import('@/views/layout/layout')
const LoginLayout = () => import('@/views/layout/login')



// 需要鉴权
const routes = [
  {
    path: '/',
    redirect: '/project',
    component: IndexLayout,
    children: [
      {
        path: 'project',
        redirect: '/project/list',
        component: Layout,
        children: [
          {
            path: 'list',
            component: Project
          },
          {
            path: 'add',
            component: ProjectAdd
          },
          {
            path: 'recycle',
            component: ProjectRecycle
          },
          {
            path: 'edit',
            component: ProjectEditInput,
            children: [
              {
                path: 'list',
                component: ProjectEditList
              },
              {
                path: 'member',
                component: ProjectEditMember
              },
              {
                path: 'archive',
                component: ProjectEditArchive
              },
              {
                path: 'data',
                component: ProjectEditData
              },
              {
                path: 'work',
                component: ProjectEditWork
              },
            ]
          }
        ]
      },
      {
        path: 'users',
        redirect: '/users/setting',
        component: Layout,
        children: [
          {
            path: 'setting',
            component: UsersSetting
          },
          {
            path: 'notification',
            component: UsersNotification
          }
        ]
      },
    ],
  },
]

// 不需要鉴权
const constant = [
  {
    path: '/',
    component: LoginLayout,
    children: [
      {
        path: '/login',
        component: Login
      },
      {
        path: '/register',
        component: Register
      },
      {
        path: '/reset',
        component: Reset
      }, // 重置密码
    ]
  }
  , // 注册页面
  { path: '/:pathMatch(.*)', redirect: '/' }
]

export default [
  ...routes,
  ...constant
]